import { fetchUtils, Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { buildAuthProvider } from "react-admin-amplify";
import { UserList, UserEdit, UserCreate } from "./users";
import { OrderList, OrderShow } from "./orders";
import { QuoteList, QuoteEdit } from "./quotes";
import jsonServerProvider from "ra-data-json-server";
import Amplify, { Auth } from "aws-amplify";
import englishMessages from "ra-language-english";


Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
  }
});

const App = () => {
  const i18nProvider = polyglotI18nProvider(locale => englishMessages, "en", {
    allowMissing: true
  });

  const httpClient = async (url: any, options?: fetchUtils.Options) => {
    if (!options) {
      options = {}
    }

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    options.headers = new Headers(
      {
        Accept: "application/json",
        Authorization: token
      }
    );

    return fetchUtils.fetchJson(url, options);
  };

  const dataProvider = jsonServerProvider(
    process.env.REACT_APP_DOMAIN,
    httpClient
  );

  return (
    <Admin
      authProvider={buildAuthProvider()}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
      <Resource name="orders" list={OrderList} show={OrderShow} />
      <Resource name="quotes" list={QuoteList} edit={QuoteEdit} />
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
      />
    </Admin>
  )
};

export default App;
