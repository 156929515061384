import { FC } from "react";
import { ListProps, ShowProps } from "react-admin";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import { QuoteOrderList, QuoteOrderShow } from "./helper";
import { IQuote, QuoteStatus } from "./types"

const toggleOrderState = (record: IQuote, dataProvider: any, notify: any) => {
  dataProvider
    .update("orders", { id: record.id, data: { isExport: true } })
    .then((response: any) => {
      if (response.statusUpdated) {
        notify("Updated order status to Processing");
      }
    })
    .catch((error: Error) => {
      notify(`Order status error: ${error.message}`, "warning");
    });
};

export const OrderList: FC<ListProps> = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const toggleState = (record: IQuote) => {
    toggleOrderState(record, dataProvider, notify);
  };

  return QuoteOrderList({ exportData: toggleState, status: true, edit: false }, props);
};

export const OrderShow: FC<ShowProps> = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const toggleState = (record: IQuote) => {
    toggleOrderState(record, dataProvider, notify);
  };

  const updateOrderState = (id: string, status: QuoteStatus, complete: { shipper: string, tracking: string }, record: IQuote) => {
    dataProvider
      .update("orders", {
        id: id,
        data: { isExport: false, status: status, complete: complete },
        previousData: record
      })
      .then(response => {
        redirect("/orders");
        notify("Order status successfully updated");
      })
      .catch(error => {
        notify(`Order status error: ${error.message}`, "warning");
      });
  };

  return (
    <QuoteOrderShow
      props={props}
      custom={{
        label: "Order",
        export: toggleState,
        update: updateOrderState
      }}
    />
  );
};
