import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Field } from "react-final-form";

import { IQuote, IAddons } from "./types"

import { formatMoney } from "./formatMoney";
import { calculateTotal } from "./calculateTotal";

function renderAddons(lineNumber: number, addons: IAddons) {
  if (typeof addons === "string") return <React.Fragment />;
  return (
    <React.Fragment>
      {Object.keys(addons).map(category => {
        return Object.keys(addons[category]).map(name => {
          const info = addons[category][name];
          return (
            <TableRow key={lineNumber}>
              <TableCell>{lineNumber++}</TableCell>
              <TableCell>{info.qty}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell colSpan={3}>
                {category} - {name}
              </TableCell>
              <TableCell>{formatMoney(info.subtotal)}</TableCell>
            </TableRow>
          );
        });
      })}
    </React.Fragment>
  );
}

export const RenderRowsTable = ({ record }: { record: IQuote }) => {
  let lineNumber = 1;
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>Qty</TableCell>
          <TableCell>Fabric</TableCell>
          <TableCell>Color</TableCell>
          <TableCell>Roller</TableCell>
          <TableCell>Motor</TableCell>
          <TableCell>Width</TableCell>
          <TableCell>Length</TableCell>
          <TableCell>Measurement</TableCell>
          <TableCell>Roll Direction</TableCell>
          <TableCell>Control Side</TableCell>
          <TableCell>Chain</TableCell>
          <TableCell>Hem Type</TableCell>
          <TableCell>Top Treatment</TableCell>
          <TableCell>Add-on</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Special Instructions</TableCell>
          <TableCell>Subtotal</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.rows.map(row => (
          <TableRow key={lineNumber}>
            <TableCell>{lineNumber++}</TableCell>
            <TableCell>{row.qty}</TableCell>
            <TableCell>{row.fabric}</TableCell>
            <TableCell>{row.color}</TableCell>
            <TableCell>{row.rollerOption}</TableCell>
            <TableCell>{row.motor}</TableCell>
            <TableCell>{row.width}</TableCell>
            <TableCell>{row.length}</TableCell>
            <TableCell>{row.measurement}</TableCell>
            <TableCell>{row.reverseRoll}</TableCell>
            <TableCell>{row.clutch}</TableCell>
            <TableCell>{row.chain}</TableCell>
            <TableCell>{row.hem}</TableCell>
            <TableCell>{row.treatment}</TableCell>
            <TableCell>{row.addon}</TableCell>
            <TableCell>{row.location}</TableCell>
            <TableCell>{row.special}</TableCell>
            <TableCell>{formatMoney(row.subtotal)}</TableCell>
          </TableRow>
        ))}

        {renderAddons(lineNumber, record.addons)}
        <TableRow>
          <TableCell colSpan={17}>Total</TableCell>
          <TableCell>{formatMoney(calculateTotal(record))}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const RowsTable = (props: { record: IQuote }) => (
  <Field name="RowsTable" component={() => RenderRowsTable(props)} />
);

export default RowsTable;
