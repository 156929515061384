import React, { useState, FC } from "react";
import {
  List,
  Edit,
  Datagrid,
  Filter,
  TextInput,
  Show,
  SimpleShowLayout,
  DateField,
  TextField,
  EmailField,
  NumberField,
  Button,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from "react-admin";

import moment from "moment";

import {
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField as TF,
} from "@material-ui/core";
import FileDownload from "@material-ui/icons/GetApp";
import HistoryIcon from "@material-ui/icons/History";
import EmailIcon from "@material-ui/icons/MailOutline";

import { formatMoney } from "./formatMoney";
import { calculateTotal } from "./calculateTotal";

import RowsTable, { RenderRowsTable } from "./rowsTable";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { IQuote, QuoteStatus, IUser } from "./types";

import "./helper.css";

const QUOTE_SHIPPED = "Shipped";
const QUOTE_STATUSES = ["Pending", "Processing", QUOTE_SHIPPED];

const dateFormat = "LLLL";

/**
 * Autofit columns by width
 *
 * @param worksheet {ExcelJS.Worksheet}
 * @param minimalWidth
 */
const autoWidth = (
  worksheet: ExcelJS.Worksheet,
  startingRow = 0,
  minimalWidth = 10
) => {
  worksheet.columns?.forEach((column) => {
    let maxColumnLength = 0;
    if (column.eachCell) {
      column.eachCell({ includeEmpty: false }, (cell) => {
        if (parseInt(cell.row) >= startingRow) {
          // ignore merged cells
          if (cell.master.address === cell.address) {
            maxColumnLength = Math.max(
              maxColumnLength,
              minimalWidth,
              cell.value ? cell.value.toString().length : 0
            );
          }
        }
      });
    }
    column.width = maxColumnLength;
  });
};

const formatRow = (row: ExcelJS.Row, start = 0) => {
  row.eachCell({ includeEmpty: true }, (cell, index) => {
    if (index > start) {
      cell.alignment = { horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  });
};

const handleExport = async (
  e: React.MouseEvent,
  record: IQuote,
  exportData: ExportData
) => {
  // useful to prevent click bubbling in a datagrid with rowClick
  e.stopPropagation();

  let filename = `${moment(record.date).format(dateFormat)} - ${
    record.company
  }`;
  if (record.sidemark) {
    filename = filename + `- ${record.sidemark}`;
  }
  filename = filename + ".xlsx";

  const workbook = new ExcelJS.Workbook();
  workbook.calcProperties.fullCalcOnLoad = true;

  const sheet = workbook.addWorksheet("Order", {
    pageSetup: { orientation: "landscape", fitToPage: true, fitToWidth: 1 },
  });
  const header = [
    ["Date", moment(record.date).format(dateFormat)],
    ["Company", record.company],
    ["Customer #", record.customerNumber],
    ["Employee", record.fullname],
    ["Pricing Factor", record.factor],
    ["Email", record.email],
    ["Phone", record.phone],
    ["Sidemark", record.sidemark],
    ["Memo", record.memo],
    ["Total", formatMoney(calculateTotal(record))],
    [],
  ];
  const headerRows = sheet.addRows(header);
  for (let i = 0; i < headerRows.length; i++) {
    sheet.mergeCells(`B${i}:C${i}`);
  }

  // Check if we have rows
  if (record.rows?.length > 0) {
    const rowHeadings = [
      "Line",
      "Qty",
      "Fabric",
      "Color",
      "Roller Type",
      "Motor",
      "Width",
      "Length",
      "Measurement",
      "Roll Direction",
      "Control Side",
      "Chain",
      "Hem Type",
      "Top Treatment",
      "Add-on",
      "Location",
      "Special Instructions",
      "Subtotal",
    ];
    const row = sheet.addRow(rowHeadings);
    formatRow(row);

    const hasData = Array(rowHeadings.length).fill(false); // default to no data
    record.rows.forEach((row, index) => {
      const excelRow = sheet.addRow([
        index + 1,
        row.qty,
        row.fabric,
        row.color,
        row.rollerOption,
        row.motor,
        row.width,
        row.length,
        row.measurement,
        row.reverseRoll,
        row.clutch,
        row.chain,
        row.hem,
        row.treatment,
        row.addon,
        row.location,
        row.special,
        formatMoney(row.subtotal),
      ]);

      // Determine if any of our cells have data
      excelRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (
          cell.value &&
          cell.value !== "" &&
          cell.value !== "None" &&
          cell.value !== "none"
        ) {
          hasData[colNumber - 1] = true;
        }
      });

      formatRow(excelRow);
    });

    // remove empty columns
    for (
      let rowIdx = row.number;
      rowIdx < row.number + record.rows?.length + 1;
      rowIdx++
    ) {
      const row = sheet.getRow(rowIdx);
      // walk backwards since columns shift left when removed
      for (let colIdx = hasData.length - 1; colIdx >= 0; colIdx--) {
        if (!hasData[colIdx]) {
          // no data for this column
          row.splice(colIdx + 1, 1);
        }
      }
    }

    // make sure columns are wide enough
    autoWidth(sheet, headerRows.length);
  }

  // check if we have addons
  if (
    record.addons &&
    Object.keys(record.addons).length !== 0 &&
    typeof record.addons !== "string"
  ) {
    sheet.addRow("");
    const row = sheet.addRow(["", "Qty", "Add-on", "", "Subtotal"]);
    // merge cells
    sheet.mergeCells(`C${row.number}:D${row.number}`);

    formatRow(row, 1);

    for (const category of Object.keys(record.addons)) {
      for (const name of Object.keys(record.addons[category])) {
        const info = record.addons[category][name];
        const row = sheet.addRow(
          [
            "",
            info.qty,
            category + " - " + name,
            "",
            formatMoney(info.subtotal),
          ],
          "i"
        );

        // merge cells
        sheet.mergeCells(`C${row.number}:D${row.number}`);
      }
    }

    // might only have add-ons
    if (record.rows?.length === 0) {
      // make sure columns are wide enough
      autoWidth(sheet, headerRows.length);
    }
  }

  const buf = await workbook.xlsx.writeBuffer();
  saveAs(
    new Blob([buf], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    filename
  );

  // Toggle status to Processing
  exportData(record);
};

interface IQuoteOrderFilterProps {
  custom: IExportButtonCustomization;
}

const QuoteOrderFilter: FC<IQuoteOrderFilterProps> = ({ custom, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    {custom.status ? (
      <SelectInput
        label="Status"
        source="status"
        choices={[
          { id: "", name: "All" },
          ...QUOTE_STATUSES.map((status) => {
            return { id: status, name: status };
          }),
        ]}
        alwaysOn
      />
    ) : null}
  </Filter>
);

type ExportData = (record: IQuote) => void;

interface IExportButtonCustomization {
  status: boolean;
  edit: boolean;
  exportData?: ExportData;
}

interface ExportButtonProps {
  basePath?: string;
  label?: string;
  record?: IQuote;
  icon?: any;
  exportData: ExportData;
}

const ExportButton: FC<ExportButtonProps> = ({
  basePath = "",
  label = "ra.action.export",
  record,
  icon = <FileDownload />,
  exportData,
  ...rest
}) => {
  if (!record) return <React.Fragment />;
  return (
    <Button
      label={label}
      onClick={(e) => handleExport(e, record, exportData)}
      {...rest}
    >
      {icon}
    </Button>
  );
};

export const QuoteOrderList = (
  custom: IExportButtonCustomization,
  props: any
) => {
  return (
    <List
      bulkActionButtons={false}
      exporter={false}
      filters={<QuoteOrderFilter custom={custom} />}
      {...props}
      sort={{ field: "date", order: "DESC" }}
    >
      <Datagrid rowClick={custom.edit ? "edit" : "show"}>
        <DateField
          source="date"
          options={{
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }}
        />
        {custom.status ? (
          <TextField source="quoteStatus" />
        ) : (
          <React.Fragment />
        )}
        <TextField source="company" />
        <TextField source="customerNumber" />
        <TextField source="fullname" />
        <NumberField source="factor" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="sidemark" />
        {custom.exportData ? (
          <ExportButton exportData={custom.exportData} />
        ) : (
          <React.Fragment />
        )}
      </Datagrid>
    </List>
  );
};

const hasShipped = (status: QuoteStatus) => {
  return status === QUOTE_SHIPPED;
};
const gridStyles = { padding: "14px 4px" };

interface IQuoteOrderShowCustomization {
  label: string;
  export: (record: IQuote) => void;
  update: (
    id: string,
    status: QuoteStatus,
    complete: { shipper: string; tracking: string },
    record: IQuote
  ) => void;
}

interface QuoteOrderShowProps {
  custom: IQuoteOrderShowCustomization;
  props: any;
}

export const QuoteOrderShow: FC<QuoteOrderShowProps> = ({ custom, props }) => (
  <Show {...props}>
    <SimpleShowLayout>
      <MyOverview custom={custom} />
    </SimpleShowLayout>
  </Show>
);

const HistoryButton = ({ record }: { record: IQuote }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button label="History" onClick={(e) => setOpen(true)}>
        <HistoryIcon />
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"lg"}
        onClose={(e) => setOpen(false)}
      >
        <DialogTitle id="history">History</DialogTitle>
        <DialogContent dividers>
          <Paper>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Info</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {record.history ? (
                    record.history?.map((row, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          {moment(row.timestamp).format(dateFormat)}
                        </TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>
                          {row.info &&
                            Object.entries(row.info).map(([key, value]) => (
                              <p key={key}>
                                {key}: {value}
                              </p>
                            ))}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        No History
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            label="Okay"
            onClick={(e) => setOpen(false)}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

interface MyOverviewProps {
  custom: IQuoteOrderShowCustomization;
  record: IQuote;
}

const MyOverview = (data: any) => {
  const { record, custom } = data as MyOverviewProps;

  // Get the shipping company and tracking # from last history record if status is shipping
  const shipping = record.history
    ?.reverse()
    .find((row) => hasShipped(row.status));

  const [status, setStatus] = useState<QuoteStatus>(record.quoteStatus);
  const [shipper, setShipper] = useState(shipping?.info?.shipper || "");
  const [tracking, setTracking] = useState(shipping?.info?.tracking || "");

  return (
    <Grid container>
      <Grid item xs={3} style={gridStyles}>
        <TF
          label="Date"
          value={moment(record.date).format(dateFormat)}
          fullWidth
        />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <Card>
          <CardContent>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value as QuoteStatus)}
                fullWidth
              >
                {QUOTE_STATUSES.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
              {hasShipped(status) ? (
                <React.Fragment>
                  <TF
                    label="Shipping Company"
                    value={shipper}
                    onChange={(e) => setShipper(e.target.value)}
                    fullWidth
                  />
                  <TF
                    label="Tracking Number"
                    value={tracking}
                    onChange={(e) => setTracking(e.target.value)}
                    fullWidth
                  />
                </React.Fragment>
              ) : null}
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              label="Update"
              onClick={(e) =>
                custom.update(
                  record.id,
                  status,
                  {
                    shipper: shipper,
                    tracking: tracking,
                  },
                  record
                )
              }
            ></Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <ExportButton record={record} exportData={custom.export} />
        <HistoryButton record={record} />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF label="Customer Number" value={record.customerNumber} fullWidth />
      </Grid>

      <Grid item xs={3} style={gridStyles}>
        <TF label="Company" value={record.company} fullWidth />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF label="Name" value={record.fullname} fullWidth />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF label="Email" value={record.email} fullWidth />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF label="Phone" value={record.phone} fullWidth />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF label="Pricing Factor" value={record.factor} fullWidth />
      </Grid>
      <Grid item xs={3} style={gridStyles}>
        <TF
          label="Sidemark"
          fullWidth
          value={record.sidemark ? record.sidemark : "Not Specified"}
        />
      </Grid>
      <Grid item xs={6} style={gridStyles}>
        <TF
          label="Memo"
          fullWidth
          value={record.memo ? record.memo : "Not Specified"}
          multiline
        />
      </Grid>
      <Grid item xs={12} style={gridStyles}>
        <RenderRowsTable record={record} />
      </Grid>
    </Grid>
  );
};

interface UserInfoProps {
  record?: IUser;
}
const UserInfo: FC<UserInfoProps> = ({ record }) => {
  if (!record) return <span>Unknown User</span>;

  return (
    <span>
      {record.given_name} {record.family_name} - {record["custom:company"]}
    </span>
  );
};

interface IQuoteOrderEditCustomization {
  label: string;
  export?: (record: IQuote) => void;
}

export const QuoteOrderEdit: FC<IQuoteOrderEditCustomization> = (
  custom,
  props: any
) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="list">
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Grid container>
                <Grid item xs={4} style={gridStyles}>
                  <ReferenceInput
                    label="User"
                    source="sub"
                    reference="users"
                    resource="users"
                    perPage={1000}
                    allowEmpty={false}
                  >
                    <SelectInput
                      FormHelperTextProps={{ classes: { root: "hide-helper" } }}
                      optionText={<UserInfo />}
                      optionValue="sub"
                    />
                  </ReferenceInput>
                  {custom.export ? (
                    <ExportButton
                      exportData={custom.export}
                      record={formData}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </Grid>
                <Grid item xs={8}>
                  <HistoryButton record={formData} />
                  <p>
                    <span>
                      <a
                        href={
                          "mailto:" +
                          formData.email +
                          "?subject=Your new quote from Hauser Shade&" +
                          "body=Here's the new quote you requested. Click on the link below to view your quote:%0D%0A%0D%0A{https://quote.hausershade.com/quotes/" +
                          formData.id
                        }
                      >
                        <EmailIcon style={{ verticalAlign: "bottom" }} />{" "}
                        {formData.fullname}
                      </a>
                    </span>
                  </p>
                </Grid>
                <Grid item xs={2} style={gridStyles}>
                  <TF
                    label="Date"
                    value={moment(formData.date).format(dateFormat)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} style={gridStyles}>
                  <TF
                    label="Sidemark"
                    fullWidth
                    value={
                      formData.sidemark ? formData.sidemark : "Not Specified"
                    }
                  />
                </Grid>
                <Grid item xs={7} style={gridStyles}>
                  <TF
                    label="Memo"
                    fullWidth
                    value={formData.memo ? formData.memo : "Not Specified"}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} style={gridStyles}>
                  <RowsTable record={formData} />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
