
export function formatMoney(
  value: number
) {
  if (value == null) {
    return "";
  }

  var formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(value);
}
