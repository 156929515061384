import { IQuote } from "./types";

export const calculateTotal = (record: IQuote) => {
  let rows = record.rows;
  let orderAddons = record.addons;

  let rowsTotal = 0.0;
  if (rows) {
    rowsTotal = rows.reduce((accumulator, currentValue) => {
      if (typeof currentValue.subtotal === "string") {
        currentValue.subtotal = parseInt(currentValue.subtotal, 10);
      }

      if (isNaN(currentValue.subtotal)) return accumulator;

      return accumulator + currentValue.subtotal;
    }, 0);
  }

  let addonsTotal = 0.0;
  if (orderAddons) {
    for (const addons of Object.values(orderAddons)) {
      for (const info of Object.values(addons)) {
        if (typeof info.subtotal === "string") {
          info.subtotal = parseInt(info.subtotal, 10);
        }
        if (isNaN(info.subtotal)) continue;

        addonsTotal += info.subtotal;
      }
    }
  }

  return rowsTotal + addonsTotal;
};
