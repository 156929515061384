import React, { FC } from "react";
import {
  List,
  ListProps,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
  EmailField,
  NumberField,
  FunctionField,
  Record,
  Edit,
  EditProps,
  SimpleForm,
  DateInput,
  TextInput,
  BooleanInput,
  Create,
  CreateProps,
  SelectInput,
  required,
  email,
  number,
  minValue,
  maxValue,
  regex,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "ra-core";
import { IUser } from "./types";

const exporter = (users: IUser[]) => {
  const usersForExport = users.map((user) => {
    // split out the address field
    user.state = user.address.region;
    user.city = user.address.locality;
    user.zip = user.address.postal_code;
    user.address = user.address.street_address;

    // rename fields
    user.first = user.given_name;
    user.last = user.family_name;
    user.company = user["custom:company"];
    user.customer_number = user["custom:customerNumber"];
    user.pricing_factor = user["custom:factor"];
    user.phone = user.phone_number;
    user.ca_resale_number = user["custom:caResaleNumber"];
    user.created_date = user.UserCreateDate;
    user.last_modified_date = user.UserLastModifiedDate;

    return user;
  });

  jsonExport(
    usersForExport,
    {
      headers: [
        "first",
        "last",
        "company",
        "pricing_factor",
        "customer_number",
        "email",
        "phone",
        "address",
        "city",
        "state",
        "zip",
        "ca_resale_number",
        "Enabled",
        "created_date",
        "last_modified_date",
      ],
    },
    (err: Error, csv: string) => {
      downloadCSV(csv, "users");
    }
  );
};

export const UserList: FC<ListProps> = (props) => (
  <List {...props} exporter={exporter}>
    <Datagrid rowClick="edit">
      <TextField source="given_name" label="First" />
      <TextField source="family_name" label="Last" />
      <TextField source="custom:company" label="Company" />
      <TextField source="custom:customerNumber" label="Customer Number" />
      <NumberField
        source="custom:factor"
        label="Pricing Factor"
        options={{ style: "percent" }}
      />
      <EmailField source="email" />
      <FunctionField
        label="Phone"
        render={(record?: Record) => {
          if (!record) return "";
          const match = record.phone_number.match(
            /^(\+1)(\d{3})(\d{3})(\d{4})$/
          );
          return `${match[1]}(${match[2]})${match[3]}-${match[4]}`;
        }}
      />
      <FunctionField
        label="Address"
        render={(record?: Record) => {
          if (!record) return "";
          if (!record.address) return <React.Fragment />;
          return (
            <div>
              {record.address.street_address}
              <br />
              {record.address.locality}, {record.address.region}{" "}
              {record.address.postal_code}
            </div>
          );
        }}
      />
      <TextField
        source="custom:caResaleNumber"
        label="California Resale Number"
      />
      <BooleanField source="Enabled" />
      <TextField source="UserStatus" label="Status" />
      <DateField source="UserCreateDate" label="Create Date" />
      <DateField source="UserLastModifiedDate" label="Last Modified" />
    </Datagrid>
  </List>
);

interface UserTitleProps {
  record?: IUser;
}

const UserTitle: FC<UserTitleProps> = ({ record }) => {
  if (!record) return <span>User unknown</span>;
  return (
    <span>
      User {record ? `${record.given_name} ${record.family_name} ` : ``}{" "}
      {record["custom:company"] ? ` from ${record["custom:company"]}` : ``}
    </span>
  );
};
const phone = [
  required(),
  regex(
    /^(\+1){0,1}[1]{0,1}[(]{0,1}[0-9]{3}[)-]{0,1}[0-9]{3}[-]{0,1}[0-9]{4}$/,
    "Must be a valid phone number"
  ),
];

const zip = [
  required(),
  regex(/^\d{5}(-\d{4}){0,1}$/, "Must be a valid Zip Code"),
];

const states = [
  { id: "AL", name: "Alabama" },
  { id: "AK", name: "Alaska" },
  { id: "AZ", name: "Arizona" },
  { id: "AR", name: "Arkansas" },
  { id: "CA", name: "California" },
  { id: "CO", name: "Colorado" },
  { id: "CT", name: "Connecticut" },
  { id: "DE", name: "Delaware" },
  { id: "FL", name: "Florida" },
  { id: "GA", name: "Georgia" },
  { id: "HI", name: "Hawaii" },
  { id: "ID", name: "Idaho" },
  { id: "IL", name: "Illinois" },
  { id: "IN", name: "Indiana" },
  { id: "IA", name: "Iowa" },
  { id: "KS", name: "Kansas" },
  { id: "KY", name: "Kentucky" },
  { id: "LA", name: "Louisiana" },
  { id: "ME", name: "Maine" },
  { id: "MD", name: "Maryland" },
  { id: "MA", name: "Massachusetts" },
  { id: "MI", name: "Michigan" },
  { id: "MN", name: "Minnesota" },
  { id: "MS", name: "Mississippi" },
  { id: "MO", name: "Missouri" },
  { id: "MT", name: "Montana" },
  { id: "NE", name: "Nebraska" },
  { id: "NV", name: "Nevada" },
  { id: "NH", name: "New Hampshire" },
  { id: "NJ", name: "New Jersey" },
  { id: "NM", name: "New Mexico" },
  { id: "NY", name: "North York" },
  { id: "NC", name: "North Carolina" },
  { id: "ND", name: "North Dakota" },
  { id: "OH", name: "Ohio" },
  { id: "OK", name: "Oklahoma" },
  { id: "OR", name: "Oregon" },
  { id: "PA", name: "Pennsylvania" },
  { id: "RI", name: "Rhode Island" },
  { id: "SC", name: "South Carolina" },
  { id: "SD", name: "South Dakota" },
  { id: "TN", name: "Tennessee" },
  { id: "TX", name: "Texas" },
  { id: "UT", name: "Utah" },
  { id: "VT", name: "Vermont" },
  { id: "VA", name: "Virginia" },
  { id: "WA", name: "Washington" },
  { id: "WV", name: "West Virginia" },
  { id: "WI", name: "Wisconsin" },
  { id: "WY", name: "Wyoming" },
];

export const UserEdit: FC<EditProps> = (props) => (
  <Edit {...props} title={<UserTitle />} undoable={false}>
    <SimpleForm variant="standard" maring="normal">
      <TextInput source="sub" label="ID" disabled />
      <TextInput source="given_name" label="First" validate={[required()]} />
      <TextInput source="family_name" label="Last" validate={[required()]} />
      <TextInput
        source="custom:company"
        label="Company"
        validate={[required()]}
      />
      <TextInput
        source="custom:customerNumber"
        label="Customer Number"
        validate={[required()]}
      />
      <TextInput
        source="email"
        label="Email"
        parse={(v: string) => v.toLowerCase()}
        validate={[required(), email()]}
      />
      <TextInput
        source="address.street_address"
        label="Address"
        validate={[required()]}
      />
      <TextInput
        source="address.locality"
        label="City"
        validate={[required()]}
      />
      <SelectInput
        source="address.region"
        label="State"
        choices={states}
        validate={[required()]}
      />
      <TextInput source="address.postal_code" label="Zip" validate={zip} />
      <TextInput source="phone_number" label="Phone" validate={phone} />
      <TextInput
        source="custom:caResaleNumber"
        label="Califoria Resale Number"
      />
      <TextInput
        source="custom:factor"
        label="Pricing Factor"
        validate={[required(), number(), minValue(25), maxValue(100)]}
      />
      <BooleanInput source="Enabled" />
      <TextInput disabled source="UserStatus" />
      <DateInput disabled source="UserCreateDate" label="Created" />
      <DateInput disabled source="UserLastModifiedDate" label="Last Modified" />
    </SimpleForm>
  </Edit>
);

export const UserCreate: FC<CreateProps> = (props) => (
  <Create {...props}>
    <SimpleForm variant="standard" margin="normal">
      <TextInput source="given_name" label="First" validate={[required()]} />
      <TextInput source="family_name" label="Last" validate={[required()]} />
      <TextInput
        source="custom:company"
        label="Company"
        validate={[required()]}
      />
      <TextInput
        source="custom:customerNumber"
        label="Customer Number"
        validate={[required()]}
      />
      <TextInput
        source="email"
        label="Email"
        parse={(v: string) => v.toLowerCase()}
        validate={[required(), email()]}
      />
      <TextInput
        source="address.street_address"
        label="Address"
        validate={[required()]}
      />
      <TextInput
        source="address.locality"
        label="City"
        validate={[required()]}
      />
      <SelectInput
        source="address.region"
        label="State"
        choices={states}
        validate={[required()]}
      />
      <TextInput source="address.postal_code" label="Zip" validate={zip} />
      <TextInput source="phone_number" label="Phone" validate={phone} />
      <TextInput
        source="custom:caResaleNumber"
        label="Califoria Resale Number"
      />
      <TextInput
        source="custom:factor"
        label="Pricing Factor"
        validate={[required(), number(), minValue(25), maxValue(100)]}
        defaultValue={"100"}
      />
      <BooleanInput source="Enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);
